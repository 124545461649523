import React, { memo } from 'react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';

export const SimpleKeyValueRow = memo(
  ({
     propertyKey,
     value,
     valueLink
   }: {
    propertyKey: string;
    value: string | undefined | number | null;
    valueLink?: string;
  }) => {
    return (
      <SimpleTableRow>
        <SimpleTableCell>{propertyKey}</SimpleTableCell>
        <SimpleTableCell>
          {valueLink ? <a href={valueLink}>{value}</a> : value}
        </SimpleTableCell>
      </SimpleTableRow>
    );
  }
);
