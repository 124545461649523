import React, { FC, memo, useEffect, useState } from 'react';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { CardFlexColumn } from '$components/cards/mui-card';
import {
  Button,
  ButtonGroup,
  CardContent,
  Typography
} from '@mui/material';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { SimpleKeyValueRow } from '$components/tables/simple-table/simple-table/simple-key-value-row';
import {
  MarkerIconEnum,
  SimDetailsQuery,
  SimLocationSierraWirelessDocument
} from '$typings/graphql-codegen';
import Map from '$components/map/map.react';
import './sim-location.css';
import Icon from '$components/icons/icon/icon.react';

interface ISimLocationProps extends ISimLocationTableProps {
  databaseData?: SimDetailsQuery;
}

const SimLocationComponent: FC<ISimLocationProps> = ({
  databaseData,
  ...rest
}: ISimLocationProps) => {
  const [t] = useCaseInsensitiveTranslation();
  const [showLocationFromController, setShowLocationFromController] = useState(
    databaseData && !(rest.latitude && rest.longitude)
  );
  const [toggledToSimAutomatically, setToggledToSimAutomatically] = useState(
    false
  );

  useEffect(() => {
    if (rest.longitude && rest.latitude && !toggledToSimAutomatically) {
      setShowLocationFromController(false);
      setToggledToSimAutomatically(true);
    }
  }, [rest]);

  return (
    <CardFlexColumn>
      <CardContent
        sx={{
          overflow: 'auto',
          flex: '1 0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <div className="flex flex_1 column">
          {showLocationFromController && databaseData ? (
            <SimLocationTable
              timeZone={databaseData?.simCardDetail?.timeZone}
              latitude={databaseData?.simCardDetail?.latitude}
              longitude={databaseData?.simCardDetail?.longitude}
              range={databaseData?.simCardDetail?.range}
            />
          ) : (
            <SimLocationTable {...rest} />
          )}
        </div>
        <div className="flex gap_m aicenter">
          <ButtonGroup>
            <Button
              variant={!showLocationFromController ? 'contained' : 'outlined'}
              onClick={() => setShowLocationFromController(false)}
              disabled={!(rest.longitude && rest.latitude)}
            >
              {t('UI_SimManagement_sim_location')}
            </Button>
            <Button
              disabled={!databaseData}
              variant={showLocationFromController ? 'contained' : 'outlined'}
              onClick={() => setShowLocationFromController(true)}
            >
              {t('UI_SimManagement_controller_location')}
            </Button>
          </ButtonGroup>
        </div>
      </CardContent>
    </CardFlexColumn>
  );
};

export const SimLocation = memo(SimLocationComponent);

interface ISimLocationTableProps {
  longitude?: number | null;
  latitude?: number | null;
  range?: number | null;
  timeZone?: string | null;
}

const SimLocationTable = memo(
  ({ longitude, latitude, range, timeZone }: ISimLocationTableProps) => {
    const [t] = useCaseInsensitiveTranslation();
    const noLocationFound = !latitude && !longitude;

    return (
      <>
        {noLocationFound ? (
          <div className="flex column aicenter flex_1">
            <div className="flex flex_1 column aicenter">
              <Icon
                name={'fa-map-marker'}
                className="sim-location-animated-icon"
              />
            </div>
            <div className="flex flex_1 column aicenter mar_tm">
              <Typography variant={'h5'}>
                {t('UI_SimManagement_SimDetails_Map_NoLocation_Header')}
              </Typography>
              <Typography mt={2} color="gray">
                {t('UI_SimManagement_SimDetails_Map_NoLocation_Text')}
              </Typography>
            </div>
          </div>
        ) : (
          <>
            {longitude && latitude && (
              <div className="sim-location-map">
                <Map
                  name='sim-location-map'
                  className="fill-height"
                  autoBounds
                  showAccuracy
                  markers={[
                    {
                      lat: latitude,
                      lng: longitude,
                      accuracy: range ?? 0,
                      controllerId: 111,
                      markerIconEnum: MarkerIconEnum.ControllerOnline,
                      title: 'Sim location',
                      markerType: 'controller'
                    }
                  ]}
                />
              </div>
            )}
            <SimpleTable>
              <SimpleKeyValueRow
                propertyKey={t('UI_SimManagement_SimDetails_Longitude')}
                value={longitude?.toFixed(2)}
              />
              <SimpleKeyValueRow
                propertyKey={t('UI_SimManagement_SimDetails_Latitude')}
                value={latitude?.toFixed(2)}
              />
              <SimpleKeyValueRow
                propertyKey={t('UI_SimManagement_SimDetails_Range')}
                value={range?.toFixed(2)}
              />
              <SimpleKeyValueRow
                propertyKey={t('UI_SimManagement_SimDetails_TimeZone')}
                value={timeZone}
              />
            </SimpleTable>
          </>
        )}
      </>
    );
  }
);

export interface ISimLocation4GProps {
  msisdn: string;
  databaseData?: SimDetailsQuery;
}

export const SimLocation4G: FC<ISimLocation4GProps> = memo(
  ({ msisdn, databaseData }: ISimLocation4GProps) => {
    const { data, loading, error } = useQuery(
      SimLocationSierraWirelessDocument,
      { msisdn, simCardType: '4G' }
    );

    return (
      <CardFlexColumn>
        {error && !loading ? (
          <SimLocation databaseData={databaseData} />
        ) : (
          <SimLocation
            range={data?.simCardDetail?.range}
            longitude={data?.simCardDetail?.longitude}
            latitude={data?.simCardDetail?.latitude}
            timeZone={data?.simCardDetail?.timeZone}
            databaseData={databaseData}
          />
        )}
      </CardFlexColumn>
    );
  }
);
