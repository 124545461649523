import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import { SimDeviceMapQuery } from '$typings/graphql-codegen';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import React from 'react';
import StringFilter from '$pages/sitelistpage-react/modules/filters/stringfilter/stringfilter.react';
import DateFilter from '$pages/sitelistpage-react/modules/filters/datefilter/datefilter.react';
import RefFilter from '$pages/sitelistpage-react/modules/filters/reffilter/reffilter';
import { ElasticSearchPage } from '$typings/graphql';
import { formatDate } from '$lib/dateHelpers';
import classNames from 'classnames';

export const defaultSelectedSimDeviceMapColumns = [
  'ICC',
  'Buid',
  'CompanyName',
  'ReceivingPlace',
  'Serial',
  'AlternateSerial',
  'ControllerAlias',
  'DeviceType',
  'RMA',
  'RMACode',
  'Imported',
  'Activated',
  'ActivatedByUserName',
  'Suspended',
  'SuspendedByUserName',
  'ShippedDate',
  'DecommissionedDate',
];

export const useSimDeviceMapColumns = () => {
  const [t] = useCaseInsensitiveTranslation();

  const columns: IReactGridColumn<
    SimDeviceMapQuery['elasticSearchPages']['simDeviceMap']['data']['edges'][0]
  >[] = [
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_ICC'),
      columnKey: 'ICC',
      required: true,
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.icc}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_ICC',
        property: 'icc',
        component: StringFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_Buid'),
      columnKey: 'Buid',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.buid?.name?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_Buid',
        property: 'buid',
        component: RefFilter,
        componentProps: { page: ElasticSearchPage.SimDeviceMap }
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_CompanyName'),
      columnKey: 'CompanyName',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.companyName}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_CompanyName',
        property: 'companyname',
        component: RefFilter,
        componentProps: { page: ElasticSearchPage.SimDeviceMap }
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_ReceivingPlace'),
      columnKey: 'ReceivingPlace',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.receivingPlace}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_ReceivingPlace',
        property: 'receivingplace',
        component: RefFilter,
        componentProps: { page: ElasticSearchPage.SimDeviceMap }
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_Serial'),
      columnKey: 'Serial',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.serial}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_Serial',
        property: 'serial',
        component: StringFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_AlternateSerial'),
      columnKey: 'AlternateSerial',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.alternateSerial}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_AlternateSerial',
        property: 'AlternateSerial',
        component: StringFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_ControllerName'),
      columnKey: 'ControllerAlias',
      hasOwnClickHandler: true,
      render: (item, width, highlightText) => (
        <GridCell
          width={width}
          highlightText={highlightText}
          showPointerIfLink
          className={classNames(item.controllerId && 'link')}
          columnLink={
            item.controllerId
              ? `controllermanager/${item.controllerId}`
              : undefined
          }
        >
          {item.controllerAlias
            ? item.controllerAlias.trim()
            : t('UI_SimManagement_DeviceSimMarriage_ControllerDoesNotExist')}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_ControllerName',
        property: 'ControllerAlias',
        component: StringFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_DeviceType'),
      columnKey: 'DeviceType',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.deviceType}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_DeviceType',
        property: 'devicetype',
        component: RefFilter,
        componentProps: { page: ElasticSearchPage.SimDeviceMap }
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_RMA'),
      columnKey: 'RMA',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.rma}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_RMA',
        property: 'RMA',
        component: StringFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_RMACode'),
      columnKey: 'RMACode',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.rmaCode}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_RMACode',
        property: 'RMACode',
        component: StringFilter
      }
    },    
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_ImportedAt'),
      columnKey: 'Imported',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.imported && formatDate(item.imported, true, '.')}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_ImportedAt',
        property: 'Imported',
        component: DateFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_SuspendedAt'),
      columnKey: 'Suspended',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.suspended && formatDate(item.suspended, true, '.')}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_SuspendedAt',
        property: 'Suspended',
        component: DateFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_SuspendedBy'),
      columnKey: 'SuspendedByUserName',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.suspendedByUserName}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_SuspendedBy',
        property: 'SuspendedByUsername',
        component: StringFilter
      }
    },

    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_ActivatedAt'),
      columnKey: 'Activated',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.activated && formatDate(item.activated, true, '.')}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_ActivatedAt',
        property: 'Activated',
        component: DateFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_ActivatedBy'),
      columnKey: 'ActivatedByUserName',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.activatedByUserName}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_ActivatedBy',
        property: 'ActivatedByUsername',
        component: StringFilter
      }
    },

    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_ShippedDate'),
      columnKey: 'ShippedDate',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.shippedDate && formatDate(item.shippedDate, true, '.')}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_ShippedDate',
        property: 'ShippedDate',
        component: DateFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_DecommissionedDate'),
      columnKey: 'DecommissionedDate',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.decommissionedDate && formatDate(item.decommissionedDate, true, '.')}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_DecommissionedDate',
        property: 'DecommissionedDate',
        component: DateFilter
      }
    },
  ];
  return columns;
};
