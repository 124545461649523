import React, { FC, memo, useState } from 'react';
import { Button, CardContent, Skeleton } from '@mui/material';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import { CardFlexColumn } from '$components/cards/mui-card';
import {
  SendSimActionDocument,
  SimDetailsQuery
} from '$typings/graphql-codegen';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import Icon from '$components/icons/icon/icon.react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { SendSimActionVariables } from '$typings/graphql';
import { ensureNumber } from '$lib/numberHelpers';

interface ISimActionsProps {
  loading?: boolean;
  simCardType: string;
  data?: SimDetailsQuery;
}

interface IActionsProps {
  data: SimDetailsQuery;
  runningAction?: string;
  sendSimAction: (action: string) => void;
  selectedActivationBuid?: string;
  setSelectedActivationBuid: (newValue?: string) => void;
}

const SimActionsComponent: FC<ISimActionsProps> = ({
  data,
  loading,
  simCardType
}: ISimActionsProps) => {
  const [selectedActivationBuid, setSelectedActivationBuid] = useState<
    string | undefined
  >(undefined);
  const [runningAction, setRunningAction] = useState<string | undefined>(
    undefined
  );

  const sendSimAction = async (action: string) => {
    setRunningAction(action);

    const vars: SendSimActionVariables = {
      action: action,
      buidId:
        action === 'ACTIVATE'
          ? ensureNumber(selectedActivationBuid ?? '-1')
          : undefined,
      icc: data?.simCardDetail?.icc,
      msisdn: data?.simCardDetail?.msisdn,
      ipadress: data?.simCardDetail?.ipAddress,
      simcardtype: simCardType
    };

    await mutate(
      SendSimActionDocument,
      vars,
      true,
      () => setRunningAction(undefined),
      () => setRunningAction(undefined)
    );
    
    
  };

  return (
    <CardFlexColumn>
      <CardContent>
        {loading || !data ? (
          <>
            {Array.from(Array(4).keys()).map(i => (
              <Skeleton key={i} width={'100%'} height={'45px'} />
            ))}
          </>
        ) : simCardType === '4G' ? (
          <SierraWirelessActions
            data={data}
            runningAction={runningAction}
            sendSimAction={sendSimAction}
            selectedActivationBuid={selectedActivationBuid}
            setSelectedActivationBuid={setSelectedActivationBuid}
          />
        ) : (
          <MaingateActions
            data={data}
            runningAction={runningAction}
            sendSimAction={sendSimAction}
            selectedActivationBuid={selectedActivationBuid}
            setSelectedActivationBuid={setSelectedActivationBuid}
          />
        )}
      </CardContent>
    </CardFlexColumn>
  );
};

export const SimActions = memo(SimActionsComponent);

const MaingateActions: FC<IActionsProps> = ({
  data,
  runningAction,
  sendSimAction,
  selectedActivationBuid,
  setSelectedActivationBuid
}) => {
  const [t] = useCaseInsensitiveTranslation();
  return (
    <SimpleTable>
      {!data?.simCardDetail?.controller && (
        <SimpleTableRow>
          <SimpleTableCell>
            {t('UI_SimManagement_SimDetails_Maingate_Actions_Header_Bind')}
          </SimpleTableCell>
          <SimpleTableCell>
            <Button
              disabled={!!runningAction}
              fullWidth
              variant={'outlined'}
              onClick={() => sendSimAction('BIND_TO_CONTROLLER')}
              startIcon={
                runningAction === 'BIND_TO_CONTROLLER' && (
                  <Icon name={'fa-spinner fa-pulse'} />
                )
              }
            >
              {t(
                'UI_SimManagement_SimDetails_Maingate_Actions_UnboundSimCard_Button'
              )}
            </Button>
          </SimpleTableCell>
        </SimpleTableRow>
      )}
      <SimpleTableRow>
        <SimpleTableCell>
          {t('UI_SimManagement_SimDetails_Maingate_Actions_Header_Bar')}
        </SimpleTableCell>
        <SimpleTableCell>
          <Button
            disabled={
              !!runningAction || data?.simCardDetail?.simState === 'Barred'
            }
            fullWidth
            variant={'outlined'}
            onClick={() => sendSimAction('SUSPEND')}
            startIcon={
              runningAction === 'SUSPEND' && (
                <Icon name={'fa-spinner fa-pulse'} />
              )
            }
          >
            {t('UI_SimManagement_SimDetails_Maingate_Actions_Bar_Button')}
          </Button>
        </SimpleTableCell>
      </SimpleTableRow>

      <SimpleTableRow>
        <SimpleTableCell>
          {t('UI_SimManagement_SimDetails_Maingate_Actions_Header_Terminate')}
        </SimpleTableCell>
        <SimpleTableCell>
          <Button
            disabled={
              !!runningAction || data?.simCardDetail?.simState === 'Terminated'
            }
            fullWidth
            variant={'outlined'}
            onClick={() => sendSimAction('TERMINATE')}
            startIcon={
              runningAction === 'TERMINATE' && (
                <Icon name={'fa-spinner fa-pulse'} />
              )
            }
          >
            {t('UI_SimManagement_SimDetails_Maingate_Actions_Terminate_Button')}
          </Button>
        </SimpleTableCell>
      </SimpleTableRow>
      <SimpleTableRow>
        <SimpleTableCell>
          {t('UI_SimManagement_SimDetails_Maingate_Actions_Header_Unbar')}
        </SimpleTableCell>
        <SimpleTableCell>
          <Button
            fullWidth
            disabled={
              !!runningAction || data?.simCardDetail?.simState === 'Activated'
            }
            variant={'outlined'}
            onClick={() => sendSimAction('RESUME')}
            startIcon={
              runningAction === 'RESUME' && (
                <Icon name={'fa-spinner fa-pulse'} />
              )
            }
          >
            {t('UI_SimManagement_SimDetails_Maingate_Actions_Unbar_Button')}
          </Button>
        </SimpleTableCell>
      </SimpleTableRow>
      <SimpleTableRow>
        <SimpleTableCell>
          {t('UI_SimManagement_SimDetails_Maingate_Actions_Header_Activate')}
        </SimpleTableCell>
        <SimpleTableCell>
          <div className="flex gap_s">
            <BuidDropdown
              className="flex_1"
              disabled={
                !!runningAction || data?.simCardDetail?.simState === 'Inactive'
              }
              selected={selectedActivationBuid}
              itemClicked={({ buidId }) => setSelectedActivationBuid(buidId)}
            />
            <Button
              disabled={
                !!runningAction ||
                data?.simCardDetail?.simState === 'Inactive' ||
                selectedActivationBuid === undefined
              }
              variant={'outlined'}
              onClick={() => sendSimAction('ACTIVATE')}
              startIcon={
                runningAction === 'ACTIVATE' && (
                  <Icon name={'fa-spinner fa-pulse'} />
                )
              }
            >
              {t(
                'UI_SimManagement_SimDetails_Maingate_Actions_Activate_Button'
              )}
            </Button>
          </div>
        </SimpleTableCell>
      </SimpleTableRow>
    </SimpleTable>
  );
};

const SierraWirelessActions: FC<IActionsProps> = ({
  data,
  runningAction,
  sendSimAction,
  selectedActivationBuid,
  setSelectedActivationBuid
}) => {
  const [t] = useCaseInsensitiveTranslation();
  return (
    <SimpleTable>
      {!data?.simCardDetail?.controller && (
        <SimpleTableRow>
          <SimpleTableCell>
            {t(
              'UI_SimManagement_SimDetails_SierraWireless_Actions_Header_Bind'
            )}
          </SimpleTableCell>
          <SimpleTableCell>
            <Button
              disabled={!!runningAction}
              fullWidth
              variant={'outlined'}
              onClick={() => sendSimAction('BIND_TO_CONTROLLER')}
              startIcon={
                runningAction === 'BIND_TO_CONTROLLER' && (
                  <Icon name={'fa-spinner fa-pulse'} />
                )
              }
            >
              {t(
                'UI_SimManagement_SimDetails_SierraWireless_Actions_UnboundSimCard_Button'
              )}
            </Button>
          </SimpleTableCell>
        </SimpleTableRow>
      )}
      <SimpleTableRow>
        <SimpleTableCell>
          {t(
            'UI_SimManagement_SimDetails_SierraWireless_Actions_Header_Suspend'
          )}
        </SimpleTableCell>
        <SimpleTableCell>
          <Button
            disabled={
              !!runningAction || data?.simCardDetail?.simState !== 'ACTIVE'
            }
            fullWidth
            variant={'outlined'}
            onClick={() => sendSimAction('SUSPEND')}
            startIcon={
              runningAction === 'SUSPEND' && (
                <Icon name={'fa-spinner fa-pulse'} />
              )
            }
          >
            {t(
              'UI_SimManagement_SimDetails_SierraWireless_Actions_Suspend_Button'
            )}
          </Button>
        </SimpleTableCell>
      </SimpleTableRow>

      <SimpleTableRow>
        <SimpleTableCell>
          {t(
            'UI_SimManagement_SimDetails_SierraWireless_Actions_Header_Terminate'
          )}
        </SimpleTableCell>
        <SimpleTableCell>
          <Button
            disabled={
              !!runningAction || data?.simCardDetail?.simState !== 'ACTIVE'
            }
            fullWidth
            variant={'outlined'}
            onClick={() => sendSimAction('TERMINATE')}
            startIcon={
              runningAction === 'TERMINATE' && (
                <Icon name={'fa-spinner fa-pulse'} />
              )
            }
          >
            {t(
              'UI_SimManagement_SimDetails_SierraWireless_Actions_Terminate_Button'
            )}
          </Button>
        </SimpleTableCell>
      </SimpleTableRow>

      <SimpleTableRow>
        <SimpleTableCell>
          {t(
            'UI_SimManagement_SimDetails_SierraWireless_Actions_Header_Resume'
          )}
        </SimpleTableCell>
        <SimpleTableCell>
          <Button
            fullWidth
            disabled={
              !!runningAction || data?.simCardDetail?.simState === 'ACTIVE'
            }
            variant={'outlined'}
            onClick={() => sendSimAction('RESUME')}
            startIcon={
              runningAction === 'RESUME' && (
                <Icon name={'fa-spinner fa-pulse'} />
              )
            }
          >
            {t(
              'UI_SimManagement_SimDetails_SierraWireless_Actions_Resume_Button'
            )}
          </Button>
        </SimpleTableCell>
      </SimpleTableRow>

      <SimpleTableRow>
        <SimpleTableCell>
          {t(
            'UI_SimManagement_SimDetails_SierraWireless_Actions_Header_Activate'
          )}
        </SimpleTableCell>
        <SimpleTableCell>
          <div className="flex gap_s">
            <BuidDropdown
              className="flex_1"
              disabled={
                !!runningAction || data?.simCardDetail?.simState === 'Inactive'
              }
              selected={selectedActivationBuid}
              itemClicked={({ buidId }) => setSelectedActivationBuid(buidId)}
            />
            <Button
              disabled={
                !!runningAction ||
                data?.simCardDetail?.simState === 'ACTIVE' ||
                data.simCardDetail?.simState === 'SUSPENDED' ||
                selectedActivationBuid === undefined
              }
              variant={'outlined'}
              onClick={() => sendSimAction('ACTIVATE')}
              startIcon={
                runningAction === 'ACTIVATE' && (
                  <Icon name={'fa-spinner fa-pulse'} />
                )
              }
            >
              {t(
                'UI_SimManagement_SimDetails_SierraWireless_Actions_Activate_Button'
              )}
            </Button>
          </div>
        </SimpleTableCell>
      </SimpleTableRow>
    </SimpleTable>
  );
};
