import {
  SimDeviceMapIdsDocument,
  SimDeviceMapQuery,
  SimDeviceMapQueryVariables
} from '$typings/graphql-codegen';
import {
  IReactGridSelectableRowsConfig,
  rowKey
} from '$components/grid/react-grid/grid.react';
import React, { useRef, useState } from 'react';
import { mutate } from '$lib/hooks/fetch-utillities';
import { Button } from '@mui/material';
import { EditBuidMapping } from '$pages/sim-management/modules/sim-device-map/modules/edit-buid-mapping';

type SimDeviceMapRow = SimDeviceMapQuery['elasticSearchPages']['simDeviceMap']['data']['edges'][0];

export const useSimDeviceMapSelectableRowsConfig = (
  variables: SimDeviceMapQueryVariables
): IReactGridSelectableRowsConfig<SimDeviceMapRow> => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [lastFetchedRowIds, setLastFetchRowIds] = useState<
    rowKey[] | undefined
  >();
  const ref = useRef<SimDeviceMapQueryVariables>();

  const selectedRowsChanged = (selectedRows: rowKey[]) => {
    setSelectedRows(selectedRows as number[]);
  };

  const fetchAllSelectableRowIds = async () => {
    if (ref.current === variables && lastFetchedRowIds) {
      return lastFetchedRowIds;
    }
    const result = await mutate(SimDeviceMapIdsDocument, variables);
    const rowKeys = result.elasticSearchPages.simDeviceMap.data.edges.map(
      c => c.simDeviceMapId
    );
    ref.current = variables;
    setLastFetchRowIds(rowKeys);
    return rowKeys;
  };

  const onClose = () => setEditModalOpen(false);

  return {
    rowKey: row => (row?.simDeviceMapId ? [row.simDeviceMapId] : []),
    selectedRows,
    selectedRowsChanged,
    selectSingleRow: false,
    selectText: selectedRows.length + ' selected',
    fetchAllSelectableRowIds,
    actionButtons: (
      <>
        <Button variant={'outlined'} onClick={() => setEditModalOpen(true)}>
          Edit
        </Button>
        {/*dismounts the component when not open to force it to reset its state */}
        {editModalOpen && ( 
          <EditBuidMapping
            editModalOpen={editModalOpen}
            onClose={onClose}
            simDeviceMapIds={selectedRows}
          />
        )}
      </>
    )
  };
};
