import React, { FC, memo, useState } from 'react';
import { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { SortDirection, ShippingLocationsDocument, ShippingLocationsQuery, ShippingLocationSortEnum } from '$typings/graphql-codegen';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import { CardFlexColumn } from '$components/cards/mui-card';
import { FreetextQueryField } from '$components/grid/modules/common/freetext-query-field';
import { Button, ButtonGroup } from '@mui/material';
import Grid from '$components/grid/react-grid/grid.react';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import Icon from '$components/icons/icon/icon.react';
import { EditShippingLocationModal } from './modules/edit-shipping-locations-modal';
import { revalidateAllActiveQueries } from '$pages/common/GraphQLFetcher';

export type ShippingLocationItem = ShippingLocationsQuery['shippingLocationMaps']['shippingLocationMap'][0];

const ShippingLocationsComponent: FC = ({}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [showShippingLocationMappingDialog, setShowShippingLocationMappingDialog] = useState(false);
  const [shippingLocationMapId, setShippingLocationMapId] = useState<number>(0);
  
  const [variables, setVariables] = useState({
    freeTextQuery: '',
    sortProperty: ShippingLocationSortEnum.Buid,
    sortDirection: SortDirection.Asc,
    offset: 0,
    first: 200
  });

  //Raw data
  const data = useGridData(
    ShippingLocationsDocument,
    variables,
    (data) => data.shippingLocationMaps.shippingLocationMap?.length || 0,
    (data) => data.shippingLocationMaps.shippingLocationMap
  ); 

  //Column definitions
  const columns: IReactGridColumn<ShippingLocationItem>[] = [
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_Buid'),
      columnKey: 'Buid',
      render: (item, width, highlightText) => {
        return (
          <GridCell width={width} highlightText={highlightText}>
            {item.buid?.name?.trim()}
          </GridCell>
        );
      },
      initialWidth: 200
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_CompanyName'),
      columnKey: 'CompanyName',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.companyName}
        </GridCell>
      ),
      initialWidth: 400
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_ReceivingPlace'),
      columnKey: 'ReceivingPlace',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.receivingPlace}
        </GridCell>
      ),
      initialWidth: 400      
    }
  ];  

  //Handle row clock (editing)
  const onRowClick = (row: ShippingLocationItem) => () => {
    if (row.shippingLocationMapId) {
      setShippingLocationMapId(row.shippingLocationMapId); 
      setShowShippingLocationMappingDialog(true); 
    }  
  };
  
  return (
    <div className="flex flex_1">      
      <CardFlexColumn className="mar_m">
        <div className="flex flex_1 column">
          <div className="flex flex_0_0_auto mar_m">
            <div className="flex flex_1 jsb">
              <div className="flex">
                <FreetextQueryField
                  value={[variables.freeTextQuery]}
                  textAreaCols={30}
                  onValueChanged={(v) => setVariables({...variables, freeTextQuery: v[0]})}
                  placeholderText={t(
                    'UI_SimManagement_DeviceSimMarriage_Filters_Textfilter_Placeholder'
                  )}
                />                
              </div>
            </div>
            <div>
              <ButtonGroup className="flex">
                <Button
                  variant="outlined"
                  onClick={() => {
                      setShippingLocationMapId(0);
                      setShowShippingLocationMappingDialog(true)
                    }
                  }                  
                  startIcon={<Icon name="fa-plus" title={t('UI_SimManagement_ShippingLocation_AddMapping')} /> }
                >                  
                {t('UI_SimManagement_ShippingLocation_AddMapping')}
                </Button>                
              </ButtonGroup>
            </div>
          </div>

          <Grid
            name={'shipping-locations'}
            highlightText={variables.freeTextQuery}
            rowClass={'clickable'}
            sortedColumnKey={variables.sortProperty}
            sortedDirection={variables.sortDirection}
            sortChanged={(sortProperty, sortDirection) =>
              setVariables(v => ({ ...v, sortProperty, sortDirection }))
            }
            columns={columns}            
            items={data}
            loading={data.isRevalidating || !data.data}            
            onRowClick={onRowClick}          
          />
        </div>
      </CardFlexColumn>

      {showShippingLocationMappingDialog && (
          <EditShippingLocationModal            
            shippingLocationMapId={shippingLocationMapId}
            onClose={() => {                 
                setShowShippingLocationMappingDialog(false); 
                setShippingLocationMapId(0);
                revalidateAllActiveQueries();
                //window.location.reload();
              } 
            }            
            editModalOpen={showShippingLocationMappingDialog}
          />
        )}     
    </div>    
  );
};

export const ShippingLocations = memo(ShippingLocationsComponent);