import React, { FC, memo, useEffect, useState } from 'react';
import { Autocomplete, Button } from '@mui/material';
import { MuiModalCard } from '$components/modals/mui-modal-card/mui-modal-card';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { Buid, DeleteShippingLocationMapDocument, SaveShippingLocationMapDocument, ShippingLocationMapDocument } from '$typings/graphql-codegen';
import ErrorText from '$components/texts/error-text/error-text.react';
import MuiTextField from '$components/textbox/mui-textfield.react';
import { useQuery, mutate } from '$lib/hooks/fetch-utillities';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import { ensureNumber } from '$lib/numberHelpers';

interface IEditMappingProps {
  editModalOpen: boolean;
  onClose: () => unknown;
  shippingLocationMapId: number | undefined;
}

const EditShippingLocationModalComponent: FC<IEditMappingProps> = ({
  shippingLocationMapId,
  editModalOpen,
  onClose
}: IEditMappingProps) => {
  const [t] = useCaseInsensitiveTranslation();
  const [companyName, setCompanyName] = useState('');
  const [receivingPlace, setReceivingPlace] = useState('');
  const [buid, setBuid] = useState<Buid | undefined>(undefined);
  const [disableSave, setDisableSave] = useState(false);
  const [activeRuleWarning, setActiveRuleWarning] = useState(false);
  
  const data = useQuery(
    ShippingLocationMapDocument,
    { shippingLocationMapId }    
  );
  
  useEffect(() => {    
    if (buid && companyName && receivingPlace)
      setDisableSave(false);
    else
      setDisableSave(true);
  }, [buid, companyName, receivingPlace]);

  //Ensure editing map is shown after data is loaded
  useEffect(() => {
    if (data && data.data && shippingLocationMapId !== 0 && data.data.shippingLocationMap.shippingLocationMap)  
    {
        const existingMap = data.data.shippingLocationMap.shippingLocationMap[0];
      
        if (existingMap.buid)
          setBuid(existingMap.buid);

        if (existingMap.companyName)
          setCompanyName(existingMap.companyName);

        if (existingMap.receivingPlace)
          setReceivingPlace(existingMap.receivingPlace);
    }
  }, [shippingLocationMapId, data]);

  async function saveMapping() {
    if (!buid)
      return;
    
    if (buid.buidId) {
      const res = await mutate(SaveShippingLocationMapDocument, {
        shippingLocationMapId,
        buidId: ensureNumber(buid.buidId),
        companyName,
        receivingPlace
      });

      if (res?.saveShippingLocationMap?.success)
        onClose();
      else
        setActiveRuleWarning(true);
    }
  }

  async function onDelete() {
    const res = await mutate(DeleteShippingLocationMapDocument, {
      shippingLocationMapId      
    });

    if (res && onClose)
      onClose();
  }

  return (
    <>
      {editModalOpen && (
        <MuiModalCard
          open={editModalOpen}
          handleClose={onClose}
          headerText={'Edit shipping location mapping'}
          footerContent={
            <>
              <Button onClick={onClose} variant="outlined" type="button">
                {t('ui_common_cancel')}
              </Button>
              {
                (shippingLocationMapId != 0) && 
                <Button onClick={onDelete} variant="contained" type='button' color={"error"}>
                  {t('ui_common_delete')}
                </Button>
              }
              <Button
                disabled={disableSave}
                onClick={async () => await saveMapping()}
                variant="contained"
                type="button"
              >
                {t('ui_common_save')}
              </Button>
            </>
          }
        >
          <div className="flex column mar_m gap_m" style={{ width: '600px' }}>
            <div className="flex aicenter">
              <div style={{ flex: '0 0 200px' }}>{t('ui_common_buid')}</div>
              <div style={{ flex: '1 0 100px', display: 'flex' }}>
                <BuidDropdown 
                    className="flex_1" 
                    itemClicked={(b) => setBuid(b)} 
                    selected={buid}
                  />
              </div>
            </div>
            
            <div className="flex aicenter">
              <div style={{ flex: '0 0 200px' }}>
                {t('UI_SimManagement_DeviceSimMarriage_CompanyName')}
              </div>
              <div style={{ flex: '1 0 100px' }}>
                <Autocomplete
                  freeSolo 
                  autoSelect     
                  disabled={shippingLocationMapId != 0}                              
                  value={companyName}                  
                  onChange={(_, newValue) => newValue && setCompanyName(newValue)}
                  renderInput={params => <MuiTextField {...params} />}
                  options={data?.data?.shippingLocationMap?.availableCompanies ?? []}                                    
                />
              </div>
            </div>
            <div className="flex aicenter">
              <div style={{ flex: '0 0 200px' }}>
                {t('UI_SimManagement_DeviceSimMarriage_ReceivingPlace')}
              </div>
              <div style={{ flex: '1 0 100px' }}>
                <Autocomplete
                  freeSolo
                  autoSelect
                  disabled={shippingLocationMapId != 0}                              
                  value={receivingPlace}
                  onChange={(_, newValue) => newValue && setReceivingPlace(newValue)}
                  renderInput={params => <MuiTextField {...params} />}
                  options={data?.data?.shippingLocationMap?.availableReceivingPlaces ?? [] }                                    
                />
              </div>
            </div>
            {activeRuleWarning && (
              <div className="flex aicenter">
                <ErrorText>
                  Already exists a mapping for this combination.
                </ErrorText>
              </div>
            )}
          </div>
        </MuiModalCard>
      )}
    </>
  );
};

export const EditShippingLocationModal = memo(EditShippingLocationModalComponent);
