import React, { FC, memo, useEffect, useState } from 'react';
import Grid from '$components/grid/react-grid/grid.react';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  ColumnKeyAndTitle,
  SimDeviceMapDocument,
  SimDeviceMapExcelDocument,
  SimDeviceMapExcelQuery,
  SimDeviceMapExcelQueryVariables,
  SimDeviceMapQueryVariables,
  SimDeviceMapSortEnum,
  SortDirection
} from '$typings/graphql-codegen';
import { ElasticSearchPage } from '$typings/graphql';
import DataFreshness from '$pages/common/data-freshness/data-freshness.react';
import { Button, ButtonGroup } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import Sliders from '$components/icons/svg/react/sliders';
import { FreetextQueryField } from '$components/grid/modules/common/freetext-query-field';
import {
  defaultSelectedSimDeviceMapColumns,
  useSimDeviceMapColumns
} from './useSimDeviceMapColumns';
import { IFilterGroup, ITextFilter } from '$interfaces/iFilter';
import { FilterList } from '$components/grid/react-grid/filter-list';
import { groupFilterDefinitions } from '$components/grid/react-grid/filter-list/filter-list';
import { variablesAreEqual } from '$components/grid/react-grid/compare-variables';
import {
  revalidateAllActiveQueries,
  runSingleQuery
} from '$pages/common/GraphQLFetcher';
import { CardFlexColumn } from '$components/cards/mui-card';
import {
  usePersistedBooleanState,
  usePersistedParsedState
} from '$lib/hooks/usePersistedState';
import { assureArray } from '$lib/arrayHelpers';
import { saveBlob } from '$lib/fileHelpers';
import {
  useSimDeviceMapSelectableRowsConfig
} from '$pages/sim-management/modules/sim-device-map/useSimDeviceMapSelectableRowsConfig';
import { useSearchParams } from 'react-router-dom';

const defaultFilters: IFilterGroup[] = [];

const defaultVariables: SimDeviceMapQueryVariables = {
  sortProperty: SimDeviceMapSortEnum.Imported,
  sortDirection: SortDirection.Desc,
  filters: JSON.stringify(defaultFilters),
  freeTextQuery: [''],
  offset: 0,
  first: 100
};

interface ISimDeviceMapProps {}

const SimDeviceMapComponent: FC<ISimDeviceMapProps> = ({}: ISimDeviceMapProps) => {
  const name = 'sim-management-sim-device-map';
  const [searchParams] = useSearchParams();
  const columns = useSimDeviceMapColumns();
  const [t] = useCaseInsensitiveTranslation();
  const [isExporting, setIsExporting] = useState(false);
  const [columnEditMode, setColumnEditMode] = useState(false);
  const [showingFilters, setShowingFilters] = usePersistedBooleanState(name + '-show-filters', false);
  const [selectedColumns, setSelectedColumns] = usePersistedParsedState<string[]>(name + '-selected-columns', defaultSelectedSimDeviceMapColumns);
  const [variables, setVariables] = usePersistedParsedState<SimDeviceMapQueryVariables>(name + '-grid-variables', defaultVariables);
  const [variablesHasChanged, setVariablesHasChanged] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');
  const [filters, setFilters] = useState<IFilterGroup[] | undefined>(variables.filters ? JSON.parse(variables.filters) : defaultFilters);

  const [freeTextQueryValue, setFreeTextQueryValue] = useState<string[]>(variables.freeTextQuery ? assureArray(variables.freeTextQuery) : []);

  const data = useGridData(
    SimDeviceMapDocument,
    variables,
    t => t.elasticSearchPages.simDeviceMap.data.totalCount,
    t => t.elasticSearchPages.simDeviceMap.data.edges
  );

  const clearFilters = () => {
    setVariables(defaultVariables);
    setFilters(defaultFilters);
    setFreeTextQueryValue([]);
  };

  const exportExcel = async () => {
    setIsExporting(true);

    const excelVariables: SimDeviceMapExcelQueryVariables = {
      ...variables,
      columns: columns
        .filter(c => selectedColumns.includes(c.columnKey))
        .map<ColumnKeyAndTitle>(c => ({
          key: c.columnKey,
          title: c.columnTitle
        }))
    };

    try {
      const { promise } = runSingleQuery<
        SimDeviceMapExcelQuery,
        SimDeviceMapExcelQueryVariables
      >(SimDeviceMapExcelDocument, excelVariables);
      const excelSheet = await promise;

      const file = await fetch(
        excelSheet.elasticSearchPages.simDeviceMap.excelSheet
      );
      const blob = await file.blob();
      saveBlob(blob, 'SimDeviceMap.xlsx');
    } catch (error) {
      throw new Error('Could not download excel file');
    } finally {
      setIsExporting(false);
    }
  };
  const numberOfActiveFilters = filters?.length;

  useEffect(() => {
    setVariables(v => ({ ...v, filters: JSON.stringify(filters) }));
  }, [filters]);

  useEffect(() => {
    setVariables(v => ({ ...v, freeTextQuery: freeTextQueryValue }));
  }, [freeTextQueryValue]);

  useEffect(() => {
    const isEqual = variablesAreEqual(variables, defaultVariables);
    setVariablesHasChanged(!isEqual);
  }, [variables]);

  useEffect(() => {
    if (searchParams) {
      checkForQueryParameters(searchParams);
    }
  }, [searchParams]);


  function checkForQueryParameters(
    params: URLSearchParams
  ) {

    const queryParamFilters: IFilterGroup[] = [];

    const buid = params.get("buid");
    if (buid) {
      const buidFilter = {
        exclude: false,
        field: 'buid',
        type: 'ref',
        filters: [{ value: buid }],
      };
        queryParamFilters?.push(buidFilter);   
    }

    const deviceTypes = params.getAll("deviceType");
    if(deviceTypes) {

      let filters : ITextFilter[] = []
      deviceTypes.forEach((type) => {
        filters.push({value: type})
      })

      const deviceTypeFilter = {
        exclude: false,
        field: 'deviceType',
        type: 'ref',
        filters: filters,
      };
        queryParamFilters?.push(deviceTypeFilter);  
    }

    if(queryParamFilters.length)
      setFilters(queryParamFilters);

    const sortProperty : SimDeviceMapSortEnum | undefined = parseEnumValue(SimDeviceMapSortEnum, params.get('sortProperty'));
    if (sortProperty) {
      setVariables(v => ({ ...v, sortProperty: sortProperty }));
    }
   
    const sortDirection : SortDirection | undefined = parseEnumValue(SortDirection, params.get('sortDirection'));
    if (sortDirection) {
      setVariables(v => ({ ...v, sortDirection: sortDirection }));
    }

  }

  //the enum string definitions are CamelCase, so lets handle if the query params are differently cased
  function parseEnumValue<T extends object>(enumType: T, param: string | null): T[keyof T] | undefined {
    if (!param) return undefined;

    const normalizedParam = param.toLowerCase();

    for (const key of Object.keys(enumType)) {
        if (key.toLowerCase() === normalizedParam) {
            return enumType[key as keyof T];
        }
    }

    return undefined;
}


  const selectableRowsConfig = useSimDeviceMapSelectableRowsConfig(variables); 
  
  const dataFreshnessPrefixText = (data.data?.length ?? 0) + ' items ';

  return (
    <div className="flex flex_1">
      {showingFilters && (
        <CardFlexColumn
          className="mar_lm mar_tm mar_bm"
          style={{ flex: '0 0 300px' }}
        >
          <FilterList
            filtersChanged={setFilters}
            activeFilters={filters}
            freeTextQuery={freeTextQueryValue}
            setDefaultFilters={clearFilters}
            variablesHasChanged={variablesHasChanged}
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
            page={ElasticSearchPage.SimDeviceMap}
            groupedFilterDefinitions={groupFilterDefinitions(columns, t)}
          />
        </CardFlexColumn>
      )}
      <CardFlexColumn className="mar_m">
        <div className="flex flex_1 column">
          <div className="flex flex_0_0_auto mar_m">
            <div className="flex flex_1 jsb">
              <div className="flex">
                <FreetextQueryField                  
                  value={freeTextQueryValue}
                  textAreaCols={30}
                  onValueChanged={setFreeTextQueryValue}
                  placeholderText={t(
                    'UI_SimManagement_DeviceSimMarriage_Filters_Textfilter_Placeholder'
                  )}
                />
                <div>
                  <ButtonGroup>
                    <Button
                      sx={{ ml: 1 }}
                      variant="outlined"
                      onClick={() => setShowingFilters(s => !s)}
                      startIcon={<Sliders className="mar_rs flex" />}
                    >
                      {numberOfActiveFilters ? numberOfActiveFilters : undefined}{' '}
                      Filters
                    </Button>
                    {variablesHasChanged && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={clearFilters}
                      >
                        <Icon name="fa-times" className="icon" />
                      </Button>
                    )}
                  </ButtonGroup>
                </div>

                <div className="flex column mar_s">
                  <span className="flex sitelist-results-info mar_lm nowrap">
                    <DataFreshness
                      className="totalcount"
                      pageToCheck={ElasticSearchPage.SimDeviceMap}
                      freshnessChanged={revalidateAllActiveQueries}
                      prefixedText={dataFreshnessPrefixText}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <ButtonGroup className="flex">
                <Button
                  variant="outlined"
                  onClick={() => setColumnEditMode(e => !e)}
                  startIcon={<Icon name="columns" />}
                >
                  {columnEditMode
                    ? t('ui_sitelist_actions_closecolumneditor')
                    : t('ui_sitelist_actions_editcolumns')}
                </Button>
                <Button
                  variant="outlined"
                  disabled={isExporting}
                  onClick={exportExcel}
                  startIcon={
                    <Icon
                      name={isExporting ? 'fa-spinner fa-pulse' : 'download'}
                    />
                  }
                >
                  {t('ui_sitelist_actions_download')}
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <Grid
            name={'device-sim-marriage'}
            sortedColumnKey={variables.sortProperty}
            sortedDirection={variables.sortDirection}
            sortChanged={(sortProperty, sortDirection) =>
              setVariables(v => ({ ...v, sortProperty, sortDirection }))
            }
            selectedColumns={selectedColumns}
            selectedColumnsChanged={setSelectedColumns}
            columnEditMode={columnEditMode}
            columns={columns}
            items={data}
            activeFilters={filters}
            filtersChanged={setFilters}
            selectableRowsConfig={selectableRowsConfig}
          />
        </div>
      </CardFlexColumn>
    </div>
  );
};

export const SimDeviceMap = memo(SimDeviceMapComponent);
