import React, { FC, memo, useEffect, useState } from 'react';
import { CardFlexColumn, CardWithHeader } from '$components/cards/mui-card';
import { useNavigate, useParams } from 'react-router-dom';
import { CardPage } from '$pages/common/card-page';
import { Button, CardContent, Skeleton, Typography } from '@mui/material';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { SimDetailsDocument } from '$typings/graphql-codegen';
import { SimLocation, SimLocation4G } from './modules/sim-location';
import { ActionsComponentType } from '$pages/common/card-page/types/actions-component-type';
import { SimInformation } from './modules/sim-information';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { SimpleKeyValueRow } from '$components/tables/simple-table/simple-table/simple-key-value-row';
import { formatDate } from '$lib/dateHelpers';
import { convertBytesToHumanReadable } from '$lib/unitConverters';
import { SimActions } from './modules/sim-actions';
import LoadingBars from '$components/loading-bars/loading-bars.react';

type SimDetailsCards = 'details' | 'location' | 'sim-actions' | 'data-usage';

interface ISimDetailsProps {
  setSimDataUsagePage: () => unknown;
}

const SimDetailsComponent: FC<ISimDetailsProps> = ({
  setSimDataUsagePage
}: ISimDetailsProps) => {
  const navigate = useNavigate();
  const [t] = useCaseInsensitiveTranslation();
  const { simCardType, msisdn } = useParams<string>();
  const [selectedMobileComponent, setSelectedMobileComponent] = useState<
    SimDetailsCards | ActionsComponentType
  >('details');

  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const goBack = () => {
    navigate(-1);
    setSimDataUsagePage();
  };

  const { data: simData, loading: loadingFromProvider } = useQuery(
    SimDetailsDocument,
    {
      msisdn,
      simCardType,
      getInfoFromProvider: true
    }
  );

  const { data: databaseData, loading: loadingFromDatabase } = useQuery(
    SimDetailsDocument,
    {
      msisdn,
      simCardType,
      getInfoFromProvider: false
    }
  );

  useEffect(() => {
    setLoadingInitialData(loadingFromDatabase || 
      (!databaseData && loadingFromProvider));
  }, [loadingFromDatabase, databaseData, loadingFromProvider]);

  const iccText = simData?.simCardDetail?.icc || databaseData?.simCardDetail?.icc 
    ? 'ICC: ' + (simData?.simCardDetail?.icc ?? databaseData?.simCardDetail?.icc ?? '') 
    : undefined;
  
  return (
    <CardPage<SimDetailsCards>
      showMenuBar={true}
      components={[
        {
          // menubar on top
          type: 'actions',
          component: (
            <CardFlexColumn className="flex_1">
              <div className="flex jsb" style={{}}>
                <div className="flex">
                  {loadingFromProvider && (
                    <div className="flex aicenter gap_m mar_lm">
                      <LoadingBars />
                      <Typography>
                        {t('UI_SimManagement_getting_sim_info_from_provider')}
                      </Typography>
                    </div>
                  )}
                  <Typography variant={'h6'} p={'1rem'}>
                    {loadingInitialData ? (
                      <Skeleton width={'280px'} />
                    ) : iccText  ? iccText : 'ㅤ'}
                  </Typography>
                </div>
                <Button onClick={goBack}>{t('ui_common_back')}</Button>
              </div>
            </CardFlexColumn>
          ),
          displayText: 'Menu',
          iconName: 'fa-list'
        },
        {
          type: 'details',
          component: (
            <SimInformation
              loading={loadingInitialData}
              databaseData={databaseData}
              simData={simData}
            />
          ),
          displayText: 'info',
          iconName: 'fa-info'
        },
        {
          type: 'location',
          component: loadingInitialData ? (
            <CardFlexColumn>
              <CardContent
                sx={{
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 0 auto'
                }}
              >
                <Skeleton variant="rounded" width="100%" sx={{ mb: 1 }}>
                  <div style={{ paddingTop: '57%' }} />
                </Skeleton>
                {Array.from(Array(4).keys()).map(i => (
                  <Skeleton key={i} width={'100%'} height={'45px'} />
                ))}
              </CardContent>
            </CardFlexColumn>
          ) : msisdn ? (
            <>
              {simCardType === '4G' ? (
                <SimLocation4G msisdn={msisdn} databaseData={databaseData} />
              ) : (
                <SimLocation
                  timeZone={simData?.simCardDetail?.timeZone}
                  latitude={simData?.simCardDetail?.latitude}
                  longitude={simData?.simCardDetail?.longitude}
                  range={simData?.simCardDetail?.range}
                  databaseData={databaseData}
                />
              )}
            </>
          ) : (
            <></>
          ),
          displayText: 'Location',
          iconName: 'fa-map-marker'
        },
        {
          type: 'sim-actions',
          displayText: 'Actions',
          iconName: 'fa-check',
          component: simCardType && (
            <SimActions
              loading={loadingFromProvider}
              data={simData}
              simCardType={simCardType}
            />
          )
        },
        {
          type: 'data-usage',
          displayText: 'data usage',
          iconName: 'fa-bar-chart',
          component: (
            <CardWithHeader headerText={'Data usage'}>
              <CardContent>
                {loadingFromProvider ? (
                  <>
                    {Array.from(Array(2).keys()).map(i => (
                      <Skeleton key={i} width={'100%'} height={'45px'} />
                    ))}
                  </>
                ) : (
                  <SimpleTable>
                    {simData?.simCardDetail?.dataUsage?.map((usage, i) => (
                      <SimpleKeyValueRow
                        key={i}
                        propertyKey={
                          usage?.timestamp
                            ? formatDate(usage.timestamp, false, '.')
                            : ''
                        }
                        value={
                          usage?.usage !== undefined && usage?.usage !== null
                            ? convertBytesToHumanReadable(usage.usage)
                            : ''
                        }
                      />
                    ))}
                  </SimpleTable>
                )}
              </CardContent>
            </CardWithHeader>
          )
        }
      ]}
      selectedMobileComponent={selectedMobileComponent}
      setSelectedMobileComponent={setSelectedMobileComponent}
    />
  );
};

export const SimDetails = memo(SimDetailsComponent);
