import React, { FC, memo, useEffect, useState } from "react";
import TextboxMultiline from "$components/textbox-multiline/textbox-multiline.react";
import { getFreeTextQueryRowCount } from "$components/grid/react-grid/gridhelper";
import { useDebounce } from "$lib/hooks/debounce";

interface IFreetextFieldProps {
  value: string[];
  onValueChanged: (newValue: string[]) => unknown;
  placeholderText: string;
  maxShownRows?: number;
  textAreaCols?: number;
}

function transformToStringValue(value: string[]) {
  return value?.join("\n") ?? "";
}

const FreetextQueryFieldComponent: FC<IFreetextFieldProps> = ({
  value,
  onValueChanged,
  placeholderText,
  textAreaCols,
  maxShownRows = 4,
}: IFreetextFieldProps) => {
  const [textAreaRows, setTextAreaRows] = useState(1);
  const [internalStringValue, setInternalStringValue] = useState<string>(
    transformToStringValue(value)
  );

  const updateRowCount = (newText: string) => {
    const rows = getFreeTextQueryRowCount(newText);
    setTextAreaRows(rows < maxShownRows ? rows : maxShownRows);
  };
  
  useEffect(() => {
    const valueAsString = transformToStringValue(value);
    setInternalStringValue(valueAsString);
    updateRowCount(valueAsString)
  }, [value]);

  const valueDebounced = useDebounce(internalStringValue?.trim(), 400);

  useEffect(() => {
    const asArray = valueDebounced?.split("\n").map((q) => q.trim());

    onValueChanged(asArray);
  }, [valueDebounced]);


  const onFreeTextQueryChange = (newText: string) => {
    updateRowCount(newText);
    setInternalStringValue(newText ?? "");
  };

  return (
    <TextboxMultiline
      restrictResizeTo="no-resize"
      rows={textAreaRows}
      cols={textAreaCols}
      value={internalStringValue}
      placeholder={placeholderText}
      onChange={(value) => onFreeTextQueryChange(value)}
      shiftEnterAsNewline
    />
  );
};

export const FreetextQueryField = memo(FreetextQueryFieldComponent);
