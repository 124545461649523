import { useGridData } from '$components/grid/react-grid/gridhelper';
import {
  ColumnKeyAndTitle,
  DecommissionedControllersExcelDocument,
  DecommissionedControllersExcelQuery,
  DecommissionedControllersExcelQueryVariables,
  DecommissionedControllersPageDocument,
  DecommissionedControllersPageQueryVariables,
  DecommissionedControllersSortEnum,
  ElasticSearchPage,
  SortDirection
} from '$typings/graphql-codegen';
import React, { FC, useEffect, useState } from 'react';
import {
  defaultSelectedDecommissionedControllersColumns,
  useDecommissionedControllersColumns
} from './useDecommissionedControllersColumns';
import Grid from '$components/grid/react-grid/grid.react';
import { FreetextQueryField } from '$components/grid/modules/common/freetext-query-field';
import { Button, ButtonGroup } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import DataFreshness from '$pages/common/data-freshness/data-freshness.react';
import { revalidateAllActiveQueries, runSingleQuery } from '$pages/common/GraphQLFetcher';
import { assureArray } from '$lib/arrayHelpers';
import { usePersistedBooleanState, usePersistedParsedState } from '$lib/hooks/usePersistedState';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { CardFlexColumn } from '$components/cards/mui-card';
import { IFilterGroup, ITextFilter } from '$interfaces/iFilter';
import { variablesAreEqual } from '$components/grid/react-grid/compare-variables';
import { FilterList } from '$components/grid/react-grid/filter-list';
import Sliders from '$components/icons/svg/react/sliders';
import { groupFilterDefinitions } from '$components/grid/react-grid/filter-list/filter-list';
import { saveBlob } from '$lib/fileHelpers';
import { useSearchParams } from 'react-router-dom';

const defaultFilters: IFilterGroup[] = [];

const defaultVariables = {
  sortProperty: DecommissionedControllersSortEnum.Decommissioned,
  sortDirection: SortDirection.Desc,
  filters: JSON.stringify(defaultFilters),
  freeTextQuery: [''],
  offset: 0,
  first: 100
};

export interface IDecommissionedControllersProps {
}

const DecommissionedControllers: FC<IDecommissionedControllersProps> = ({}) => {
  const name = 'sim-management-decomissioned-controllers';
  const [searchParams] = useSearchParams();
  const [selectedColumns, setSelectedColumns] = usePersistedParsedState<string[]>(name + '-selected-columns', defaultSelectedDecommissionedControllersColumns);
  const [columnEditMode, setColumnEditMode] = useState(false);
  const columns = useDecommissionedControllersColumns();
  const [t] = useCaseInsensitiveTranslation();
  const [variablesHasChanged, setVariablesHasChanged] = useState(false);
  const [showingFilters, setShowingFilters] = usePersistedBooleanState(name + '-show-filters', false);
  const [variables, setVariables] = usePersistedParsedState<DecommissionedControllersPageQueryVariables>(name + '-grid-variables', defaultVariables);
  const [filters, setFilters] = useState<IFilterGroup[] | undefined>(variables.filters ? JSON.parse(variables.filters) : undefined);
  const [freeTextQueryValue, setFreeTextQueryValue] = useState<string[]>(variables.freeTextQuery ? assureArray(variables.freeTextQuery) : []);
  const [filterQuery, setFilterQuery] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const gridData = useGridData(
    DecommissionedControllersPageDocument,
    variables,
    d => d.elasticSearchPages.decommissionedControllers.data.totalCount,
    d => d.elasticSearchPages.decommissionedControllers.data.edges
  );

  const dataFreshnessPrefixText = (gridData.data?.length ?? 0) + ' items ';
  const numberOfActiveFilters = filters?.length;

  const exportExcel = async () => {
    setIsExporting(true);

    const excelVariables: DecommissionedControllersExcelQueryVariables = {
      ...variables,
      columns: columns
        .filter(c => selectedColumns.includes(c.columnKey))
        .map<ColumnKeyAndTitle>(c => ({
          key: c.columnKey,
          title: c.columnTitle
        }))
    };

    try {
      const { promise } = runSingleQuery<
        DecommissionedControllersExcelQuery,  
        DecommissionedControllersExcelQueryVariables
      >(DecommissionedControllersExcelDocument, excelVariables);
      const excelSheet = await promise;

      const file = await fetch(
        excelSheet.elasticSearchPages.decommissionedControllers.excelSheet
      );
      const blob = await file.blob();
      saveBlob(blob, 'DecommissionedControllers.xlsx');
    } catch (error) {
      throw new Error('Could not download excel file');
    } finally {
      setIsExporting(false);
    }
  };

  const clearFilters = () => {
    setVariables(defaultVariables);
    setFilters(defaultFilters);
    setFreeTextQueryValue([]);
  };

  useEffect(() => {
    if (searchParams) {
      checkForQueryParameters(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    setVariables(v => ({ ...v, filters: JSON.stringify(filters) }));
  }, [filters]);

  useEffect(() => {
    setVariables(v => ({ ...v, freeTextQuery: freeTextQueryValue }));
  }, [freeTextQueryValue]);

  useEffect(() => {
    const isEqual = variablesAreEqual(variables, defaultVariables);
    setVariablesHasChanged(!isEqual);
  }, [variables]);




  function checkForQueryParameters(
    params: URLSearchParams
  ) {

    const queryParamFilters: IFilterGroup[] = [];

    const buid = params.get("buid");
    if (buid) {
      const buidFilter = {
        exclude: false,
        field: 'buid',
        type: 'ref',
        filters: [{ value: buid }],
      };
        queryParamFilters?.push(buidFilter);   
    }

    const controllerTypes = params.getAll("controllerType");
    if(controllerTypes) {

      let filters : ITextFilter[] = []
      controllerTypes.forEach((type) => {
        filters.push({value: type})
      })

      const controllerTypeFilter = {
        exclude: false,
        field: 'controllerType',
        type: 'ref',
        filters: filters,
      };
        queryParamFilters?.push(controllerTypeFilter);  
    }

    if(queryParamFilters.length)
      setFilters(queryParamFilters);

  }

  return (
    <div className="flex flex_1">
      {showingFilters && (
        <CardFlexColumn
          className="mar_lm mar_tm mar_bm"
          style={{ flex: '0 0 300px' }}
        >
          <FilterList
            filtersChanged={setFilters}
            activeFilters={filters}
            freeTextQuery={freeTextQueryValue}
            setDefaultFilters={clearFilters}
            variablesHasChanged={variablesHasChanged}
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
            page={ElasticSearchPage.SimDeviceMap}
            groupedFilterDefinitions={groupFilterDefinitions(columns, t)}
          />
        </CardFlexColumn>
      )}
      <CardFlexColumn className="mar_m">
        <div className="flex flex_1 column">
          <div className="flex flex_0_0_auto mar_m">
            <div className="flex flex_1 jsb">
              <div className="flex">
                <FreetextQueryField
                  value={freeTextQueryValue}
                  textAreaCols={30}
                  onValueChanged={setFreeTextQueryValue}
                  placeholderText={t(
                    'UI_SimManagement_DeviceSimMarriage_Filters_Textfilter_Placeholder'
                  )}
                />
                <div>
                  <ButtonGroup>
                    <Button
                      sx={{ ml: 1 }}
                      variant="outlined"
                      onClick={() => setShowingFilters(s => !s)}
                      startIcon={<Sliders className="mar_rs flex" />}
                    >
                      {numberOfActiveFilters
                        ? numberOfActiveFilters
                        : undefined}{' '}
                      Filters
                    </Button>
                    {variablesHasChanged && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={clearFilters}
                      >
                        <Icon name="fa-times" className="icon" />
                      </Button>
                    )}
                  </ButtonGroup>
                </div>

                <div className="flex column mar_s">
                  <span className="flex sitelist-results-info mar_lm nowrap">
                    <DataFreshness
                      className="totalcount"
                      pageToCheck={ElasticSearchPage.DecommissionedControllers}
                      freshnessChanged={revalidateAllActiveQueries}
                      prefixedText={dataFreshnessPrefixText}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <ButtonGroup className="flex">
                <Button
                  variant="outlined"
                  onClick={() => setColumnEditMode(e => !e)}
                  startIcon={<Icon name="columns" />}
                >
                  {columnEditMode
                    ? t('ui_sitelist_actions_closecolumneditor')
                    : t('ui_sitelist_actions_editcolumns')}
                </Button>
                <Button
                  variant="outlined"
                  disabled={isExporting}
                  onClick={exportExcel}
                  startIcon={
                    <Icon
                      name={isExporting ? 'fa-spinner fa-pulse' : 'download'}
                    />
                  }>
                  {t('ui_sitelist_actions_download')}
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <Grid
            name={name}
            columns={columns}
            items={gridData}
            sortedColumnKey={variables.sortProperty}
            sortedDirection={variables.sortDirection}
            sortChanged={(sortProperty, sortDirection) =>
              setVariables(v => ({ ...v, sortProperty, sortDirection }))
            }
            selectedColumns={selectedColumns}
            selectedColumnsChanged={setSelectedColumns}
            activeFilters={filters}
            filtersChanged={setFilters}
            columnEditMode={columnEditMode}
            highlightText={variables.freeTextQuery || undefined}
          />
        </div>
      </CardFlexColumn>
    </div>
  );
};

export default DecommissionedControllers;
