import React, { FC, memo, useEffect,  useState } from 'react';
import { Card, styled, Tab, Tabs } from '@mui/material';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useNavigate
} from 'react-router-dom';
import Icon from '$components/icons/icon/icon.react';
import { SimDeviceMap } from './modules/sim-device-map';
import { SimDataUsage } from './modules/sim-data-usage';
import { ShippingLocations } from './modules/shipping-locations';
import { SimDetails } from './modules/sim-details';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import DecommissionedControllers from './modules/decommissioned-controllers/decommissioned-controllers.react';

enum PageType {
  SimDeviceMap = 'sim-device-map',
  SimDataUsage = 'sim-data-usage',
  SimDetails = 'sim-details',
  ShippingLocations = 'shipping-locations',
  DecommissionedControllers = 'decommissioned-controllers'
}

export interface ISimManagementProps {
  defaultPage: PageType;
}

const StyledTab = styled(Tab)({
  minHeight: 'auto',
  padding: '1rem',
  fontSize: '1rem'
});

const simManagmentPath = '/sim-management';
const SimManagementComponent: FC<ISimManagementProps> = ({
  defaultPage = PageType.SimDeviceMap
}: ISimManagementProps) => {
  const [activePage, setActivePage] = useState(defaultPage);
  
  function updateTabStateFromUrl() {
    if (window.location.href.match(/sim-details/)) {
      setActivePage(PageType.SimDetails);
    } else if (window.location.href.match(/sim-data-usage/)) {
      setActivePage(PageType.SimDataUsage);
    } else if (window.location.href.match(/sim-device-map/)) {
      setActivePage(PageType.SimDeviceMap);
    } else if (window.location.href.match(/shipping-locations/)) {
      setActivePage(PageType.ShippingLocations);
    } else if (window.location.href.match(/decommissioned-controllers/)) {
      setActivePage(PageType.DecommissionedControllers);
    }
  }

  const setSimDataUsagePage = () => setActivePage(PageType.SimDataUsage);
  useEffect(() => {
    updateTabStateFromUrl();
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<Navigate to={simManagmentPath} />}>
        <Route
          path={simManagmentPath}
          element={
            <SimManagementTabs
              activePage={activePage}
              setActivePage={setActivePage}
            />
          }
        >
          <Route
            index
            path={PageType.SimDeviceMap + '?'}
            element={<SimDeviceMap />}
          />
          <Route
            path={PageType.SimDataUsage}
            element={<SimDataUsage />}
          />
            <Route
            path={PageType.ShippingLocations}
            element={<ShippingLocations />}
          />
          <Route
            path={PageType.DecommissionedControllers}
            element={<DecommissionedControllers />}
          />
        </Route>
        <Route 
          path={simManagmentPath + "/" + PageType.SimDetails + '/:simCardType/:msisdn'}
          element={<SimDetails setSimDataUsagePage={setSimDataUsagePage} />}
        />
      </Route>
    )
  );
  return <RouterProvider router={router} />;
};

export const SimManagement = memo(SimManagementComponent);

interface ISimManagementGridsProps {
  activePage: PageType;
  setActivePage: (page: PageType) => unknown;
}

const SimManagementTabs: FC<ISimManagementGridsProps> = memo(
  ({ activePage, setActivePage }: ISimManagementGridsProps) => {
    const [t] = useCaseInsensitiveTranslation();
    const nav = useNavigate();

    const setActivePageAndNavigate = (page: PageType) => {
      setActivePage(page);
      nav(page);
    };

    return (
      <>
        <Card className="mar_rm mar_lm mar_tm">
          <Tabs
            sx={{
              '& button.Mui-selected': {
                backgroundColor: '#fafafa'
              },
              '& button:hover': {
                backgroundColor: '#fafafa'
              },
              '& button': { transition: 'background-color 0.5s ease;' }
            }}
            variant={'fullWidth'}
            value={activePage}
            onChange={(_, newValue) => {
              setActivePageAndNavigate(newValue);
            }}
          >
            <StyledTab
              label={t('UI_SimManagement_Menu_DeviceSimMarriage')}
              value={PageType.SimDeviceMap}
              iconPosition={'start'}
              icon={<Icon name={'fa-microchip'} />}
            />
            <StyledTab
              label={t('UI_SimManagement_Menu_DataUsage')}
              value={PageType.SimDataUsage}
              iconPosition={'start'}
              icon={<Icon name={'fa-bar-chart'} />}
            />
            <StyledTab
              label={"Shipping Locations"}
              value={PageType.ShippingLocations}
              iconPosition={'start'}
              icon={<Icon name={'fa-truck'} />}
            />
            <StyledTab
              label={t('UI_SimManagement_Menu_DecommissionedControllers')}
              value={PageType.DecommissionedControllers}
              iconPosition={'start'}
              icon={<Icon name={'fa-microchip'} />}
            />
          </Tabs>
        </Card>
        <div className="flex column" style={{flex:"1"}}>
          <Outlet />
        </div>
      </>
    );
  }
);
