import React, { FC, memo, useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import BuidDropdown from '$components/dropdowns/buid-dropdown/buid-dropdown.react';
import { MuiModalCard } from '$components/modals/mui-modal-card/mui-modal-card';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import { SimDeviceBuidMappingDocument, UpdateSimDeviceMapBuidDocument } from '$typings/graphql-codegen';
import ErrorText from '$components/texts/error-text/error-text.react';
import { ensureNumber } from '$lib/numberHelpers';
import { revalidateAllActiveQueries } from '$pages/common/GraphQLFetcher';

const filterUnique = <T,>(value: T, index: number, array: T[]) =>
  array.indexOf(value) === index;

interface IEditBuidMappingProps {
  editModalOpen: boolean;
  onClose: () => unknown;
  simDeviceMapIds: number[];
}

const EditBuidMappingComponent: FC<IEditBuidMappingProps> = ({
  simDeviceMapIds,
  editModalOpen,
  onClose
}: IEditBuidMappingProps) => {
  const [t] = useCaseInsensitiveTranslation();
  const [selectedBuid, setSelectedBuid] = useState<string | undefined>(
    undefined
  );
  const [companyName, setCompanyName] = useState('');
  const [receivingPlace, setReceivingPlace] = useState('');
  const { data, loading } = useQuery(SimDeviceBuidMappingDocument, {
    simDeviceMapIds: simDeviceMapIds
  });
  const [differentValuesWarninig, setDifferentValuesWarninig] = useState(false);

  useEffect(() => {
    if (loading || !data?.simDeviceBuidMapping) return;
    const onlyOneId = data.simDeviceBuidMapping.length === 1;

    if (onlyOneId) {
      const {
        companyName: companyNameFromQuery,
        receivingPlace: receivingPlaceFromQuery,
        buid
      } = data.simDeviceBuidMapping[0];

      if (companyNameFromQuery) {
        setCompanyName(companyNameFromQuery);
      }
      if (receivingPlaceFromQuery) {
        setReceivingPlace(receivingPlaceFromQuery);
      }
      if (buid?.buidId) {
        setSelectedBuid(buid.buidId);
      }
    } else if (data.simDeviceBuidMapping.length > 0) {
      // dont assign values unless they are the same for all

      const buidIds = data.simDeviceBuidMapping.map(m => m.buid?.buidId);
      const uniqueBuidIds = buidIds.filter(filterUnique);

      //Find unique non-null
      const companyNames = data.simDeviceBuidMapping.filter(m => m.companyName).map(m => m.companyName);
      const uniqueCompanyNames = companyNames.filter(filterUnique);

      const receivingPlaces = data.simDeviceBuidMapping.filter(m => m.receivingPlace).map(m => m.receivingPlace);
      const uniqueReceivingPlaces = receivingPlaces.filter(filterUnique);
      
      if (uniqueCompanyNames.length > 1 || uniqueReceivingPlaces.length > 1) {
        setDifferentValuesWarninig(true);
      }

      const selectionHasTheSameValues =
        uniqueBuidIds.length <= 1 &&
        uniqueCompanyNames.length <= 1 &&
        uniqueReceivingPlaces.length <= 1;

      if (selectionHasTheSameValues) {
        uniqueCompanyNames[0] && setCompanyName(uniqueCompanyNames[0]);
        uniqueReceivingPlaces[0] && setReceivingPlace(uniqueReceivingPlaces[0]);
        uniqueBuidIds[0] && setSelectedBuid(uniqueBuidIds[0]);
      } else {
        setDifferentValuesWarninig(true);
      }
    } // else none is received for some reason
  }, [data, simDeviceMapIds]);

  //Save new buid on SimDeviceMap
  const updateBuid = async () => {
    if (!selectedBuid) return;
    
    const res = await mutate(UpdateSimDeviceMapBuidDocument, {
      buidId: ensureNumber(selectedBuid),
      simDeviceMapIds: simDeviceMapIds
    });

    if (res?.updateSimDeviceMapBuid?.success) {
      revalidateAllActiveQueries();
      onClose();
    }      
  }

  return (
    <>
      {editModalOpen && (
        <MuiModalCard
          open={editModalOpen}
          handleClose={onClose}
          headerText={'Edit sim device mappings'}
          footerContent={
            <>
              <Button onClick={onClose} variant="outlined" type="button">
                {t('ui_common_cancel')}
              </Button>
              <Button
                onClick={updateBuid}
                variant="contained"
                type="button"
              >
                {t('UI_RequireConfirmation_ConfirmOkButton')}
              </Button>
            </>
          }
        >
          <div className="flex column mar_m gap_m" style={{ width: '600px' }}>
            <div className="flex aicenter">
              <div style={{ flex: '0 0 200px' }}>{t('ui_common_buid')}</div>
              <div style={{ flex: '1 0 100px', display: 'flex' }}>
                <BuidDropdown
                  className="flex_1"
                  itemClicked={item => setSelectedBuid(item.buidId)}
                  selected={selectedBuid}
                />
              </div>
            </div>            
            <div className="flex aicenter">
              <div style={{ flex: '0 0 200px' }}>
                {t('UI_SimManagement_DeviceSimMarriage_CompanyName')}
              </div>
              <div style={{ flex: '1 0 100px' }}>
                <TextField
                  disabled={true}
                  value={companyName}                 
                />
              </div>
            </div>
            <div className="flex aicenter">
              <div style={{ flex: '0 0 200px' }}>
                {t('UI_SimManagement_DeviceSimMarriage_ReceivingPlace')}
              </div>
              <div style={{ flex: '1 0 100px' }}>
                <TextField
                  disabled={true}
                  value={receivingPlace}                  
                />
              </div>
            </div>                        
            {differentValuesWarninig && (
              <div className="flex aicenter">
                <ErrorText>
                  Warning! Editing multiple rows with different buid, company names and/or receiving places!
                </ErrorText>
              </div>
            )}                       
          </div>
        </MuiModalCard>
      )}
    </>
  );
};

export const EditBuidMapping = memo(EditBuidMappingComponent);

