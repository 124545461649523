import GridCell from '$components/grid/react-grid/cell/grid-cell';
import React from 'react';
import { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  DecommissionedControllersPage,
  DecommissionedControllersSortEnum,
  ElasticSearchPage
} from '$typings/graphql-codegen';
import StringFilter from '$pages/sitelistpage-react/modules/filters/stringfilter/stringfilter.react';
import RefFilter from '$pages/sitelistpage-react/modules/filters/reffilter/reffilter';
import { formatDate, pureFormatDate } from '$lib/dateHelpers';
import DateFilter from '$pages/sitelistpage-react/modules/filters/datefilter/datefilter.react';
import BooleanFilter from '$pages/sitelistpage-react/modules/filters/booleanfilter/booleanfilter.react';

export const defaultSelectedDecommissionedControllersColumns = [
  DecommissionedControllersSortEnum.Serial,
  DecommissionedControllersSortEnum.AlternateSerial,
  DecommissionedControllersSortEnum.Alias,
  DecommissionedControllersSortEnum.Buid,
  DecommissionedControllersSortEnum.ControllerType,
  DecommissionedControllersSortEnum.Decommissioned,
  DecommissionedControllersSortEnum.DecommissionedBy,
  DecommissionedControllersSortEnum.DecommissionReason,
  DecommissionedControllersSortEnum.DecommissionReasonText,
  DecommissionedControllersSortEnum.Icc,
  DecommissionedControllersSortEnum.IpAddress,
  DecommissionedControllersSortEnum.LastSampleTime,
  DecommissionedControllersSortEnum.Provider,
  DecommissionedControllersSortEnum.SimActive,
  DecommissionedControllersSortEnum.SimCardType,
  DecommissionedControllersSortEnum.SimNotes,
];

export const useDecommissionedControllersColumns = () => {
  const [t] = useCaseInsensitiveTranslation();

  const columns: IReactGridColumn<
    DecommissionedControllersPage['data']['edges'][0]
  >[] = [
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_serial'
      ),
      columnKey: DecommissionedControllersSortEnum.Serial,
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.serial}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_serial',
        property: 'serial',
        component: StringFilter
      }
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_alternateserial'
      ),
      columnKey: DecommissionedControllersSortEnum.AlternateSerial,
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.alternateSerial}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name:
          'ui_controllermanager_controllerlist_table_headers_alternateserial',
        property: 'alternateSerial',
        component: StringFilter
      }
    },
    {
      columnTitle: t('ui_controllermanager_controllerlist_table_headers_alias'),
      columnKey: DecommissionedControllersSortEnum.Alias,
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.alias?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_alias',
        property: 'alias',
        component: StringFilter
      }
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_controllertype'
      ),
      columnKey: DecommissionedControllersSortEnum.ControllerType,
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.controllerType}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name:
          'ui_controllermanager_controllerlist_table_headers_controllertype',
        property: 'controllerType',
        component: RefFilter,
        componentProps: { page: ElasticSearchPage.DecommissionedControllers }
      }
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_lastsampletime'
      ),
      columnKey: DecommissionedControllersSortEnum.LastSampleTime,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item.lastSampleTime &&
            pureFormatDate(item.lastSampleTime, true, '.')}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name:
          'ui_controllermanager_controllerlist_table_headers_lastsampletime',
        property: 'lastsampletime',
        component: DateFilter
      }
    },
    
    {
      columnTitle: t(
        'ui_SimManagement_decommissionedController_Decommissioned'
      ),
      columnKey: DecommissionedControllersSortEnum.Decommissioned,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item.decommissioned && formatDate(item.decommissioned, true, '.')}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_SimManagement_decommissionedController_Decommissioned',
        property: 'Decommisioned',
        component: DateFilter
      }
    },
    {
      columnTitle: t(
        'ui_SimManagement_decommissionedController_DecommissionedBy'
      ),
      columnKey: DecommissionedControllersSortEnum.DecommissionedBy,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item.decommissionedBy}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_SimManagement_decommissionedController_DecommissionedBy',
        property: 'DecommisionedBy',
        component: StringFilter
      }
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_decommissionreason'
      ),
      columnKey: DecommissionedControllersSortEnum.DecommissionReason,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item.decommissionReason?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_decommissionreason',
        property: 'decommissionreason',
        component: RefFilter,
        componentProps: { page: ElasticSearchPage.DecommissionedControllers }
      }
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_decommissionreasontext'
      ),
      columnKey: DecommissionedControllersSortEnum.DecommissionReasonText,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item.decommissionReasonText?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_decommissionreasontext',
        property: 'decommissionreasontext',
        component: StringFilter
      }
    },

    {
      columnTitle: t('ui_controllermanager_controllerlist_table_headers_buid'),
      columnKey: DecommissionedControllersSortEnum.Buid,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.buid?.name?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_buid',
        property: 'buid',
        component: RefFilter,
        componentProps: { page: ElasticSearchPage.DecommissionedControllers }
      }
    },
    {
      columnKey: DecommissionedControllersSortEnum.IpAddress,
      columnTitle: t('ui_controllermanager_controllerlist_table_headers_ipaddress'),
      initialWidth: 200,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item.ipAddress?.trim()}
        </GridCell>
      ),
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_ipaddress',
        property: 'ipAddress',
        component: StringFilter
      }
    },
    {
      columnTitle: t('ui_controllermanager_controllerlist_table_headers_icc'),
      columnKey: DecommissionedControllersSortEnum.Icc,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.icc?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_icc',
        property: 'icc',
        component: StringFilter
      }
    },
    {
      columnTitle: t('ui_SimManagement_decommissionedController_SimCardType'),
      columnKey: DecommissionedControllersSortEnum.SimCardType,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.simCardType}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_SimManagement_decommissionedController_SimCardType',
        component: RefFilter,
        property: 'simcardtype',
        componentProps: { page: ElasticSearchPage.DecommissionedControllers }
      }
    },
    {
      columnTitle: t('ui_SimManagement_decommissionedController_SimActive'),
      columnKey: DecommissionedControllersSortEnum.SimActive,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item?.simActive ? "Active" : "Not active"}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_SimManagement_decommissionedController_SimActive',
        property: 'SimActive',
        component: BooleanFilter,
        componentProps: {
          values: [
            {
              value: true,
              header: 'Active',
              sub: 'Show only active sim cards'
            },
            {
              value: false,
              header: 'Not active',
              sub: 'Show only active sim cards'
            },
            {
              value: undefined,
              default: true,
              header: 'Both',
              sub: 'Do not filter by this property'
            }
          ]
        }
    }
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_provider'
      ),
      columnKey: DecommissionedControllersSortEnum.Provider,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item.provider?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_provider',
        property: 'provider',
        component: StringFilter
      }
    },
    {
      columnTitle: t(
        'ui_controllermanager_controllerlist_table_headers_simnotes'
      ),
      columnKey: DecommissionedControllersSortEnum.SimNotes,
      render: (item, width, highlightText) => (
        <GridCell highlightText={highlightText} width={width}>
          {item.simNotes?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'ui_controllermanager_controllerlist_table_headers_simnotes',
        property: 'simnotes',
        component: StringFilter
      }
    }
  ];

  return columns;
};
