import { SimDetailsQuery } from '$typings/graphql-codegen';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { CardFlexColumn } from '$components/cards/mui-card';
import { CardContent, Skeleton } from '@mui/material';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { SimpleKeyValueRow } from '$components/tables/simple-table/simple-table/simple-key-value-row';
import { formatDate } from '$lib/dateHelpers';
import React, { FC, memo } from 'react';

interface ISimInformation {
  loading: boolean;
  databaseData?: SimDetailsQuery;
  simData?: SimDetailsQuery;
}

const SimInformationComponent: FC<ISimInformation> = ({
  loading,
  simData,
  databaseData
}: ISimInformation) => {
  const [t] = useCaseInsensitiveTranslation();

  const controllerLink =
    '/controllermanager/' +
    databaseData?.simCardDetail?.controller?.controllerId;

  return (
    <CardFlexColumn>
      <CardContent sx={{ overflow: 'auto' }}>
        {loading ? (
          <>
            {Array.from(Array(10).keys()).map(i => (
              <Skeleton key={i} width={'100%'} height={'45px'} />
            ))}
          </>
        ) : (
          <SimpleTable>
            {databaseData?.simCardDetail?.controller?.controllerId ? (
              <>
                <SimpleKeyValueRow
                  propertyKey={t('UI_SimManagement_SimDetails_ControllerName')}
                  value={databaseData?.simCardDetail?.controller?.name}
                  valueLink={controllerLink}
                />
                <SimpleKeyValueRow
                  propertyKey={t('UI_SimManagement_SimDetails_ControllerAlias')}
                  value={databaseData?.simCardDetail?.controller?.alias}
                  valueLink={controllerLink}
                />
                <SimpleKeyValueRow
                  propertyKey={t(
                    'UI_SimManagement_SimDetails_ControllerSerial'
                  )}
                  value={databaseData?.simCardDetail.controller.serial}
                  valueLink={controllerLink}
                />
                {databaseData?.simCardDetail.controller.alternateSerial && (
                  <SimpleKeyValueRow
                    propertyKey={t(
                      'UI_SimManagement_SimDetails_ControllerAlternateSerial'
                    )}
                    value={
                      databaseData?.simCardDetail.controller.alternateSerial
                    }
                    valueLink={controllerLink}
                  />
                )}
              </>
            ) : (
              <SimpleKeyValueRow
                propertyKey={t('UI_SimManagement_SimDetails_ControllerName')}
                value={t(
                  'UI_SimManagement_SimDetails_SimCardNotBoundToController'
                )}
              />
            )}
            {databaseData?.simCardDetail?.site?.siteId && (
              <SimpleKeyValueRow
                propertyKey={t('UI_SimManagement_SimDetails_SiteName')}
                value={databaseData?.simCardDetail.site.alias}
                valueLink={
                  '/sitedetails/' + databaseData?.simCardDetail.site.siteId
                }
              />
            )}
            <SimpleKeyValueRow
              propertyKey={t('UI_SimManagement_SimDetails_ICC')}
              value={
                simData?.simCardDetail?.icc ?? databaseData?.simCardDetail?.icc
              }
            />
            <SimpleKeyValueRow
              propertyKey={t('UI_SimManagement_SimDetails_IpAddress')}
              value={
                simData?.simCardDetail?.ipAddress ??
                databaseData?.simCardDetail?.ipAddress
              }
            />
            <SimpleKeyValueRow
              propertyKey={t('UI_SimManagement_SimDetails_MSISDN')}
              value={
                simData?.simCardDetail?.msisdn ??
                databaseData?.simCardDetail?.msisdn
              }
            />
            <SimpleKeyValueRow
              propertyKey={t('UI_SimManagement_SimDetails_IMSI')}
              value={
                databaseData?.simCardDetail?.imsi ??
                simData?.simCardDetail?.imsi
              }
            />
            <SimpleKeyValueRow
              propertyKey={t('UI_SimManagement_SimDetails_IMEI')}
              value={
                databaseData?.simCardDetail?.imei ??
                simData?.simCardDetail?.imei
              }
            />
            <SimpleKeyValueRow
              propertyKey={t('UI_SimManagement_SimDetails_BuidName')}
              value={
                databaseData?.simCardDetail?.buidName ??
                simData?.simCardDetail?.buidName
              }
            />
            <SimpleKeyValueRow
              propertyKey={t('UI_SimManagement_SimDetails_Provider')}
              value={
                databaseData?.simCardDetail?.provider ??
                simData?.simCardDetail?.provider
              }
            />
            <SimpleKeyValueRow
              propertyKey={t('UI_SimManagement_SimDetails_SimState')}
              value={simData?.simCardDetail?.simState}
            />
            {(simData?.simCardDetail?.terminationStatus === 'TP' ||
              simData?.simCardDetail?.terminationDate) && (
              <SimpleKeyValueRow
                propertyKey={t(
                  'UI_SimManagement_SimDetails_TerminationPending'
                )}
                value={
                  simData?.simCardDetail?.terminationDate
                    ? formatDate(
                        simData.simCardDetail.terminationDate,
                        false,
                        '.'
                      )
                    : undefined
                }
              />
            )}
          </SimpleTable>
        )}
      </CardContent>
    </CardFlexColumn>
  );
};

export const SimInformation = memo(SimInformationComponent);
