import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IReactGridColumn } from '$components/grid/react-grid/grid.react';
import { SimDataUsageQuery } from '$typings/graphql-codegen';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import React from 'react';
import StringFilter from '$pages/sitelistpage-react/modules/filters/stringfilter/stringfilter.react';
import RefFilter from '$pages/sitelistpage-react/modules/filters/reffilter/reffilter';
import { ElasticSearchPage } from '$typings/graphql';
import { convertBytesToHumanReadable } from '$lib/unitConverters';
import { getMonthAsLanguageText } from '$lib/dateHelpers';
import classNames from 'classnames';

export const defaultSelectedSimDataUsageColumns = [
  'SiteAlias',
  'IpAddress',
  'MSISDN',
  'ICC',
  'IMEI',
  'Month',
  'Usage',
  'SimCardType',
  'ControllerAlias',
  'AlternateSerial',
  'Supplier',
  'Buid'
  // 'Actions'
];

export const useSimDataUsageColumns = () => {
  const [t] = useCaseInsensitiveTranslation();

  const columns: IReactGridColumn<
    SimDataUsageQuery['elasticSearchPages']['simDataUsage']['data']['edges'][0]
  >[] = [
    {
      columnTitle: t('UI_SimManagement_DataUsageMaingate_SiteName'),
      columnKey: 'SiteAlias',
      hasOwnClickHandler: true,
      render: (item, width, highlightText) => (
        <GridCell
          width={width}
          highlightText={highlightText}
          showPointerIfLink
          className={classNames(item.siteId && 'link')}
          columnLink={item.siteId ? `sitedetails/${item.siteId}` : undefined}
        >
          {item.siteAlias?.trim()}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DataUsageMaingate_SiteName',
        component: StringFilter,
        property: 'sitealias'
      }
    },
    {
      columnTitle: t('UI_SimManagement_DataUsageMaingate_IpAddress'),
      columnKey: 'IpAddress',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.ipAddress}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DataUsageMaingate_IpAddress',
        component: StringFilter,
        property: 'ipaddress'
      }
    },
    {
      columnTitle: t('UI_SimManagement_DataUsageMaingate_MSISDN'),
      columnKey: 'MSISDN',
      required: true,
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.msisdn}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DataUsageMaingate_MSISDN',
        component: StringFilter,
        property: 'msisdn'
      }
    },
    {
      columnTitle: t('UI_SimManagement_DataUsageMaingate_ICC'),
      columnKey: 'ICC',
      required: true,
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.icc}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DataUsageMaingate_ICC',
        component: StringFilter,
        property: 'icc'
      }
    },
    {
      columnTitle: t('UI_SimManagement_SimDetails_IMEI'),
      columnKey: 'IMEI',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.imei}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_SimDetails_IMEI',
        component: StringFilter,
        property: 'imei'
      }
    },
    {
      columnTitle: t('UI_SimManagement_DataUsageMaingate_Month'),
      columnKey: 'Month',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.timestamp && t(getMonthAsLanguageText(item.timestamp) ?? '')}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DataUsageMaingate_Month',
        component: RefFilter,
        property: 'month',
        componentProps: { page: ElasticSearchPage.SimDataUsage }
      }
    },
    {
      columnTitle: t('UI_SimManagement_DataUsageMaingate_DataUsage'),
      columnKey: 'Usage',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.usage && convertBytesToHumanReadable(item.usage)}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DataUsageMaingate_DataUsage',
        property: 'usage',
        component: StringFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DataUsageMaingate_SimCardType'),
      columnKey: 'SimCardType',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.simCardType}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DataUsageMaingate_SimCardType',
        component: RefFilter,
        property: 'simcardtype',
        componentProps: { page: ElasticSearchPage.SimDataUsage }
      }
    },
    {
      columnTitle: t('UI_SimManagement_DataUsageMaingate_ControllerName'),
      columnKey: 'ControllerAlias',
      hasOwnClickHandler: true,
      render: (item, width, highlightText) => (
        <GridCell
          width={width}
          highlightText={highlightText}
          showPointerIfLink
          className={classNames(item.controllerId && 'link')}
          columnLink={
            item.controllerId
              ? `controllermanager/${item.controllerId}`
              : undefined
          }
        >
          {item.controllerAlias
            ? item.controllerAlias.trim()
            : t('UI_SimManagement_DeviceSimMarriage_ControllerDoesNotExist')}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DataUsageMaingate_ControllerName',
        property: 'controlleralias',
        component: StringFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DataUsageMaingate_AlternateSerial'),
      columnKey: 'AlternateSerial',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.alternateSerial}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DataUsageMaingate_AlternateSerial',
        property: 'alternateserial',
        component: StringFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_Serial'),
      columnKey: 'Serial',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.serial}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: t('UI_SimManagement_DeviceSimMarriage_Serial'),
        property: 'serial',
        component: StringFilter
      }
    },
    {
      columnTitle: t('UI_SimManagement_DataUsageMaingate_Supplier'),
      columnKey: 'Supplier',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.supplier}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DataUsageMaingate_Supplier',
        property: 'supplier',
        component: RefFilter,
        componentProps: {
          page: ElasticSearchPage.SimDataUsage
        }
      }
    },
    {
      columnTitle: t('UI_SimManagement_DeviceSimMarriage_Buid'),
      columnKey: 'Buid',
      render: (item, width, highlightText) => (
        <GridCell width={width} highlightText={highlightText}>
          {item.buid?.name}
        </GridCell>
      ),
      initialWidth: 200,
      filter: {
        name: 'UI_SimManagement_DeviceSimMarriage_Buid',
        property: 'buid',
        component: RefFilter,
        componentProps: {
          page: ElasticSearchPage.SimDataUsage
        }
      }
    }
    // {
    //   columnTitle: t('UI_SimManagement_DataUsageMaingate_Actions'),
    //   columnKey: 'Actions',
    //   render: (_, width, highlightText) => (
    //     <GridCell width={width} highlightText={highlightText}>
    //       Action!....
    //     </GridCell>
    //   ),
    //   initialWidth: 200
    // }
  ];
  return columns;
};
