import React, { FC, memo, useEffect, useState } from 'react';
import {
  defaultSelectedSimDataUsageColumns,
  useSimDataUsageColumns
} from './useSimDataUsageColumns';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  SimDataUsageDocument,
  SimDataUsageQueryVariables,
  SimDataUsageSortEnum,
  SortDirection,
  SimDataUsageExcelQueryVariables,
  SimDataUsageExcelQuery,
  SimDataUsageExcelDocument,
  ColumnKeyAndTitle,
  SimDataUsageQuery,
} from '$typings/graphql-codegen';
import { ElasticSearchPage } from '$typings/graphql';
import { assureArray } from '$lib/arrayHelpers';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import { FreetextQueryField } from '$components/grid/modules/common/freetext-query-field';
import { Button, ButtonGroup } from '@mui/material';
import Sliders from '$components/icons/svg/react/sliders';
import Icon from '$components/icons/icon/icon.react';
import DataFreshness from '$pages/common/data-freshness/data-freshness.react';
import Grid from '$components/grid/react-grid/grid.react';
import { CardFlexColumn } from '$components/cards/mui-card';
import { FilterList } from '$components/grid/react-grid/filter-list';
import { groupFilterDefinitions } from '$components/grid/react-grid/filter-list/filter-list';
import { IFilterGroup } from '$interfaces/iFilter';
import { variablesAreEqual } from '$components/grid/react-grid/compare-variables';
import { revalidateAllActiveQueries, runSingleQuery } from '$pages/common/GraphQLFetcher';
import { saveBlob } from '$lib/fileHelpers';
import { useNavigate } from 'react-router-dom';
import { usePersistedBooleanState, usePersistedParsedState } from '$lib/hooks/usePersistedState';

const defaultFilters: IFilterGroup[] = [];

const defaultVariables: SimDataUsageQueryVariables = {
  sortProperty: SimDataUsageSortEnum.Icc,
  sortDirection: SortDirection.Asc,
  filters: JSON.stringify(defaultFilters),
  freeTextQuery: [''],
  offset: 0,
  first: 100
};

export type SimDataUsageItem = SimDataUsageQuery['elasticSearchPages']['simDataUsage']['data']['edges'][0];

interface ISimDataUsageProps {}

const SimDataUsageComponent: FC<ISimDataUsageProps> = ({}) => {
  const name = 'sim-management-data-usage';
  const navigate = useNavigate();
  const columns = useSimDataUsageColumns();
  const [t] = useCaseInsensitiveTranslation();
  const [isExporting, setIsExporting] = useState(false);
  const [columnEditMode, setColumnEditMode] = useState(false);
  const [showingFilters, setShowingFilters] = usePersistedBooleanState(name + '-show-filters', false);
  const [selectedColumns, setSelectedColumns] = usePersistedParsedState<string[]>(name + '-selected-columns', defaultSelectedSimDataUsageColumns);
  const [variables, setVariables] = usePersistedParsedState<SimDataUsageQueryVariables>(name + '-grid-variables', defaultVariables);
  const [variablesHasChanged, setVariablesHasChanged] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');
  const [filters, setFilters] = useState<IFilterGroup[] | undefined>(variables.filters ? JSON.parse(variables.filters) : defaultFilters);

  const [freeTextQueryValue, setFreeTextQueryValue] = useState<string[]>(variables.freeTextQuery ? assureArray(variables.freeTextQuery) : []);

  const data = useGridData(
    SimDataUsageDocument,
    variables,
    t => t.elasticSearchPages.simDataUsage.data.totalCount,
    t => t.elasticSearchPages.simDataUsage.data.edges
  );

  const clearFilters = () => {
    setVariables(defaultVariables);
    setFilters(defaultFilters);
    setFreeTextQueryValue([]);
  };

  const exportExcel = async () => {
    setIsExporting(true);

    const excelVariables: SimDataUsageExcelQueryVariables = {
      ...variables,
      columns: columns
        .filter(c => selectedColumns.includes(c.columnKey))
        .map<ColumnKeyAndTitle>(c => ({
          key: c.columnKey,
          title: c.columnTitle
        }))
    };

    try {
      const { promise } = runSingleQuery<
        SimDataUsageExcelQuery,
        SimDataUsageExcelQueryVariables
      >(SimDataUsageExcelDocument, excelVariables);
      const excelSheet = await promise;

      const file = await fetch(
        excelSheet.elasticSearchPages.simDataUsage.excelSheet
      );
      const blob = await file.blob();
      saveBlob(blob, 'SimDataUsage.xlsx');
    } catch (error) {
      throw new Error('Could not download excel file');
    } finally {
      setIsExporting(false);
    }
  };

  const numberOfActiveFilters = filters?.length;

  useEffect(() => {
    setVariables(v => ({ ...v, filters: JSON.stringify(filters) }));
  }, [filters]);

  useEffect(() => {
    setVariables(v => ({ ...v, freeTextQuery: freeTextQueryValue }));
  }, [freeTextQueryValue]);

  useEffect(() => {
    const isEqual = variablesAreEqual(variables, defaultVariables);
    setVariablesHasChanged(!isEqual);
  }, [variables]);

  const dataFreshnessPrefixText = (data.data?.length ?? 0) + ' items ';

  const rowLink = (item: SimDataUsageItem) => {
    return item?.simCardType !== undefined && item?.msisdn != undefined
      ? `sim-management/sim-details/${
          item.simCardType
        }/${item.msisdn.replace('+', '')}`
      : undefined;
  };
  const onRowClick = (item: SimDataUsageItem) => () => {
    const url = `/sim-management/sim-details/${
      item.simCardType
    }/${item?.msisdn?.replace('+', '')}`;
    navigate(url, { replace: true });
  };

  return (
    <div className="flex flex_1">
      {showingFilters && (
        <CardFlexColumn
          className="mar_lm mar_tm mar_bm"
          style={{ flex: '0 0 300px' }}
        >
          <FilterList
            filtersChanged={setFilters}
            activeFilters={filters}
            freeTextQuery={freeTextQueryValue}
            setDefaultFilters={clearFilters}
            variablesHasChanged={variablesHasChanged}
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
            page={ElasticSearchPage.SimDataUsage}
            groupedFilterDefinitions={groupFilterDefinitions(columns, t)}
          />
        </CardFlexColumn>
      )}
      <CardFlexColumn className="mar_m">
        <div className="flex flex_1 column">
          <div className="flex flex_0_0_auto mar_m">
            <div className="flex flex_1 jsb">
              <div className="flex">
                <FreetextQueryField
                  value={freeTextQueryValue}
                  textAreaCols={30}
                  onValueChanged={setFreeTextQueryValue}
                  placeholderText={t(
                    'UI_SimManagement_DeviceSimMarriage_Filters_Textfilter_Placeholder'
                  )}
                />
                <div>
                  <ButtonGroup>
                    <Button
                      sx={{ ml: 1 }}
                      variant="outlined"
                      onClick={() => setShowingFilters(s => !s)}
                      startIcon={<Sliders className="mar_rs flex" />}
                    >
                      {numberOfActiveFilters
                        ? numberOfActiveFilters + ' '
                        : undefined}
                      Filters
                    </Button>
                    {variablesHasChanged && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={clearFilters}
                      >
                        <Icon name="fa-times" className="icon" />
                      </Button>
                    )}
                  </ButtonGroup>
                </div>
                <div className="flex column mar_s">
                  <span className="flex sitelist-results-info mar_lm nowrap">
                    <DataFreshness
                      className="totalcount"
                      pageToCheck={ElasticSearchPage.SimDataUsage}
                      freshnessChanged={revalidateAllActiveQueries}
                      prefixedText={dataFreshnessPrefixText}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <ButtonGroup className="flex">
                <Button
                  variant="outlined"
                  onClick={() => setColumnEditMode(e => !e)}
                  startIcon={<Icon name="columns" />}
                >
                  {columnEditMode
                    ? t('ui_sitelist_actions_closecolumneditor')
                    : t('ui_sitelist_actions_editcolumns')}
                </Button>
                <Button
                  variant="outlined"
                  disabled={isExporting}
                  onClick={exportExcel}
                  startIcon={
                    <Icon
                      name={isExporting ? 'fa-spinner fa-pulse' : 'download'}
                    />
                  }
                >
                  {t('ui_sitelist_actions_download')}
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <Grid
            name={'sim-data-usage'}
            sortedColumnKey={variables.sortProperty}
            sortedDirection={variables.sortDirection}
            sortChanged={(sortProperty, sortDirection) =>
              setVariables(v => ({ ...v, sortProperty, sortDirection }))
            }
            selectedColumns={selectedColumns}
            selectedColumnsChanged={setSelectedColumns}
            columnEditMode={columnEditMode}
            columns={columns}
            items={data}
            activeFilters={filters}
            filtersChanged={setFilters}
            rowLink={rowLink}
            onRowClick={onRowClick}
          />
        </div>
      </CardFlexColumn>
    </div>
  );
};

export const SimDataUsage = memo(SimDataUsageComponent);
